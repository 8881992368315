<template>
  <v-container fluid class="pa-0">
    <!--    <div class="imagen"></div>-->
    <!--    <div class="img-cont">-->
    <!--      <img src="../../../../assets/images/inicio.png" alt="" class="img-inicio" />-->
    <!--    </div>-->
    <BaseCard heading="INICIO">
      <div>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <!--              <h1>Inicio</h1>-->
              <!--              <v-btn @click="bulk">bulk</v-btn>-->
              <!--              <v-btn @click="getbulk">getbulk</v-btn>-->
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </BaseCard>
  </v-container>
</template>

<script>
export default {
  name: 'Inicio',
  data: () => ({})
};
</script>
<style lang="scss">
.img-cont {
  overflow: hidden;
}

.img-inicio {
  width: 100%;
  height: calc(100vh - 61px - 64px - 24px);
}
</style>
